<template>
    <div>
        <div class="columns is-mobile is-multiline">
            <div class="column is-full-mobile">
                <div class="field">
                    <label class="label">{{ i18n('Subject') }}</label>
                    <div class="control">
                        <input class="input" type="text" v-model="birthday.subject" disabled>
                    </div>
                </div>
            </div>

            <div class="column is-full-mobile">
                <div class="field">
                    <label class="label">{{ i18n('Message') }}</label>
                    <div class="control">
                        <input class="input" type="text" v-model="birthday.message" disabled>
                    </div>
                </div>
            </div>

            <div class="column is-full-mobile">
                <div class="field">
                    <label class="label">{{ i18n('Program') }}</label>
                    <div class="control" v-if="birthday.program">
                        <input class="input" type="text" v-model="birthday.program.name" disabled>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Show',
    inject: ['route', 'http', 'i18n'],
    data() {
        return {
            birthday: {
                subject: '',
                message: '',
                program: {
                    name: '',
                },
            },
        };
    },

    mounted() {
        this.fetchData();
    },

    methods: {
        fetchData() {
            this.http
                .get(this.route('birthday.show', { birthday: this.$route.params.birthday }))
                .then(({ data }) => {
                    this.birthday = data.birthday;
                })
                .catch(this.errorHandler);
        },
    },
};
</script>

<style lang="scss" scoped>
.medal {
    width: 10rem;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

@media (max-width: 748px) {
    .medal {
        width: 5.5rem;
    }
}
</style>
